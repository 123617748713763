@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: var(--font-family);
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-value {
  color: #000 !important;
}

.mat-horizontal-stepper-header-container,
.mat-step-header {
  background-color: #424242 !important;
}

.mat-horizontal-content-container {
  padding: 0px 0px 24px 0px !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 34px;
}

.mat-button-toggle-appearance-standard {
  color: #424242 !important;
  background: #fff !important;
  height: 42px !important; /* Match height of textboxes */
  line-height: 42px !important;
}
.mat-button-toggle-checked {
  color: #fff !important;
  background: #424242 !important;
}

.mat-button-toggle-button {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: #000 !important;
}

.mat-mdc-select-value {
  color: #000 !important;
}
